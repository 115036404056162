import { isMobile } from '../utils/index'
export default {
  address: '台南市東區光明街2號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.657500570465!2d120.22591061496729!3d22.999618784964632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e76ea77f781e3%3A0xcfa5eb9bd742e77f!2zNzAx5Y-w5Y2X5biC5p2x5Y2A5YWJ5piO6KGXMuiZnw!5e0!3m2!1szh-TW!2stw!4v1599015332983!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/nM5YLo1SfGVomWMZ8',
  phone: '06-208-8822',
  fbLink: 'https://www.facebook.com/chunfuhomehotel/',
  fbMessage: 'https://m.me/chunfuhomehotel',
  caseName: '春福采采',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '春福機構·新春建設',],
    ['建築代銷', '上揚國際建築團隊',],
    ['建造號碼', '108南工造字第04415號',],
  ],

  gtmCode: ['555BGF4'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '立即預約',
    subTitle: ''
  }
}
